import React, { useCallback } from 'react';
import {
    Subscribe,
    Form,
    FormTitle,
    InputWrapper,
    Label,
    Field,
    Button,
} from './footerStyles';
import { DataLayerPush } from 'utils/analytics';

export const FooterSubscribe = () => {
    const handleSubmit = useCallback(e => {
        e.preventDefault();
        DataLayerPush({
            event: 'newsletter_subscription',
        });
        e.target.submit();
    }, []);

    return (
        <Subscribe>
            <Form
                onSubmit={e => handleSubmit(e)}
                action={`https://form.flodesk.com/forms/655c701c0775ca153c491fe9/submit`}
                method={`post`}
            >
                <FormTitle>
                    <strong>Subscribe to the Calico newsletter</strong>
                    {` `}for news, insights, and inspiration
                </FormTitle>
                <InputWrapper>
                    <Field name="confirm_email_address" type={`hidden`} />
                    <Label htmlFor={`fieldEmail`}>Email</Label>
                    <Field
                        id={`fieldEmail`}
                        name={`email`}
                        type={`email`}
                        placeholder={`Your email`}
                        required={`required`}
                    />
                    <Button type={`submit`}>Subscribe</Button>
                </InputWrapper>
            </Form>
        </Subscribe>
    );
};

export default FooterSubscribe;
