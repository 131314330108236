import tw, { styled } from 'twin.macro';
import { H4, Small } from '../styles/typographyStyles';
import { Container } from '../styles/containerStyles';
import { LinkButton } from '../styles/typographyStyles';

export const LogoRow = tw.div`w-full flex items-center justify-between flex-wrap md:flex-nowrap pb-30 md:pb-72 lg:pb-100`;
export const Logo = styled.a`
    ${tw`max-w-200 h-88 md:max-w-240 md:h-104 lg:max-w-288 lg:h-132 w-full -ml-10 xl:-ml-40 mb-32 md:mb-0`}
    svg {
        width: 100%;
        height: 100%;
    }
`;
export const CtaRow = tw.div`md:pl-6per`;
export const CtaTitle = styled(H4)`
    ${tw`text-white pb-22 xl:pb-24`}
    text-indent: -0.15em;
`;
export const CtaLinks = tw.div`flex items-start justify-start lg:justify-between lg:items-center flex-wrap xl:justify-start`;
export const CtaLink = styled.a`
    ${tw`text-15 w-full md:w-auto lg:text-18 py-7 md:pt-0 lg:pb-0 md:mr-20 lg:mr-40 md:pb-10 md:pl-0 text-white hover:text-theme-color transition-colors duration-300 font-bold leading-none`}
    &:last-child {
        ${tw`lg:mr-20`}
    }
`;
export const FollowRow = tw.div`w-full flex items-center justify-between flex-wrap pb-48 lg:pb-100 md:flex-row-reverse`;
export const Icons = tw.div`pb-50 lg:pb-0 flex items-center justify-start lg:justify-end`;
export const IconLink = styled.a`
    ${tw`w-full h-30 pr-20 mr-4 flex items-center text-white`}
    svg {
        height: 100%;
        fill: ;
    }
    &:last-child {
        ${tw`pr-0 mr-0`}
    }
    &:hover {
        svg,
        svg * {
            fill: var(--themeColour);
            ${tw`transition-colors duration-500`}
        }
    }
`;
export const Subscribe = tw.div`w-full md:max-w-3/5 lg:max-w-7/10 flex items-center justify-start`;
export const Form = tw.form`w-full flex items-center justify-start flex-wrap lg:flex-nowrap`;
export const FormTitle = tw.p`lg:max-w-300 lg:pr-30 text-white pb-16 lg:pb-0 xl:box-content`;
export const InputWrapper = tw.div`flex items-stretch border border-calico-footer-form hover:border-calico-tag transition-colors duration-500 rounded w-full lg:w-500 bg-calico-subscribe-background`;
export const Label = tw.label`hidden`;
export const Field = styled.input`
    ${tw`p-20 bg-transparent text-white text-15 md:text-18 leading-1.1 w-full outline-none lg:min-w-250`}
    &::placeholder {
        ${tw`text-white`}
    }
`;
export const Button = tw.button`text-white leading-1.1 font-bold p-20 outline-none hover:text-theme-color transition-colors duration-500`;

export const Sub = tw.div`border-t border-calico-footer-border pt-20 flex items-center justify-between flex-wrap md:flex-nowrap`;
export const Location = styled.a`
    ${tw`flex items-center justify-start pb-20 md:pb-0 text-white hover:text-theme-color transition-colors duration-500`}
    svg {
        fill: currentColor;
    }
`;
export const MapPin = tw.span`w-24`;
export const Address = tw(Small)`pl-4 pb-0`;
export const LinkGroup = tw.div`flex items-center justify-between w-full md:w-auto`;
export const BrandLink = styled.a`
    ${tw`flex items-center w-128 h-52 lg:h-57 lg:w-160 text-white`}
    svg {
        ${tw`opacity-70`}
    }
    &:hover {
        svg,
        svg * {
            fill: var(--themeColour);
            ${tw`transition-all duration-500 opacity-100`}
        }
    }
`;
export const FooterContainer = styled(Container)`
    ${tw`bg-90per md:bg-66per xl:bg-footer-xl`}
`;
export const FooterInner = tw(
    Container
)`z-1 relative pt-32 pb-100 md:py-72 lg:pt-100 `;
export const ContactButton = tw(
    LinkButton
)`inline-block lg:flex mr-20 lg:mr-40 normal-case py-13 px-30 text-18`;
export const ButtonWrapper = tw.div`w-full lg:w-auto mb-24 lg:mb-0`;

export const TraditionContainer = tw(
    Container
)`bg-calico-grey-dark z-1 pb-64 md:pb-0`;
export const TraditionWrapper = tw.div`w-full flex flex-wrap md:flex-nowrap items-center justify-start pt-38 pb-50`;
export const Flags = tw.div`flex items-center justify-start mr-12 mb-24 md:mb-0`;
export const ImageWrapper = tw.span`block mr-18 w-52 min-w-52 h-auto`;
export const TraditionImage = tw.img`block`;
export const TraditionCopy = tw(Small)`text-white pb-0`;
