import React, { useMemo } from 'react';
import { DomData } from 'utils/dom';

export const Icon = ({ name, attributes }) => {
    const { isBrowser, domLoaded } = DomData();
    const IconElement = useMemo(
        () =>
            isBrowser && domLoaded && name
                ? React.lazy(() => import(`static/icons/${name}.svg`))
                : null,
        [name, isBrowser, domLoaded]
    );

    return useMemo(
        () =>
            IconElement ? (
                <React.Suspense fallback={<svg></svg>}>
                    <IconElement {...attributes} />
                </React.Suspense>
            ) : null,
        [IconElement, attributes]
    );
};

export default Icon;
