import { useState, useEffect } from 'react';
import { DomData } from './dom';

export const Routes = () => {
    const { isBrowser } = DomData();
    const [hash, setHash] = useState(
        isBrowser ? window.location.hash.replace('#', '') : ''
    );

    useEffect(() => {
        if (hash) {
            window.history.replaceState(null, null, `#${hash}`);
        } else {
            window.history.replaceState(null, null, ``);
        }
    }, [hash]);

    useEffect(() => {
        if (isBrowser) {
            function updateHash() {
                setHash(window.location.hash.replace('#', ''));
            }
            document.addEventListener('hashchange', updateHash);
            updateHash();
            return () => document.removeEventListener('hashchange', updateHash);
        }
    }, [isBrowser]);

    return { hash, setHash };
};

export const matchCurrentPath = (path, compare) => {
    if (compare !== '/home/') {
        const isSelfOrChild = path?.includes(compare?.replace('/home', ''));
        if (isSelfOrChild) {
            return true;
        }
        if (compare?.includes('/about/')) {
            return path?.includes('/news-insights/');
        }
    }
    return false;
};
