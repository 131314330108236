import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

export const DomData = () => {
    const isBrowser =
        typeof window !== undefined && typeof window !== 'undefined';
    const [domLoaded, setDomLoaded] = useState(false);
    const [screenWidth, setScreenWidth] = useState('');

    useEffect(() => {
        if (isBrowser) {
            function updateWidth() {
                if (window.innerWidth >= 1440) {
                    if (screenWidth !== 'xl') {
                        setScreenWidth('xl');
                    }
                } else {
                    if (window.innerWidth >= 992) {
                        if (screenWidth !== 'desktop') {
                            setScreenWidth('desktop');
                        }
                    } else {
                        if (window.innerWidth >= 768) {
                            if (screenWidth !== 'tablet') {
                                setScreenWidth('tablet');
                            }
                        } else {
                            if (screenWidth !== 'small') {
                                setScreenWidth('small');
                            }
                        }
                    }
                }
            }
            window.addEventListener('resize', updateWidth);
            updateWidth();
            return () => window.removeEventListener('resize', updateWidth);
        }
    }, [isBrowser, screenWidth]);

    useEffect(() => {
        if (isBrowser) {
            function updateDomLoaded() {
                if (document.readyState === 'complete') {
                    setDomLoaded(true);
                }
            }
            if (!domLoaded) {
                document.addEventListener('readystatechange', updateDomLoaded);
                updateDomLoaded();
                return () =>
                    document.removeEventListener(
                        'readystatechange',
                        updateDomLoaded
                    );
            }
        }
    }, [isBrowser, domLoaded]);

    return { isBrowser, screenWidth, domLoaded };
};

export const ScrollStatus = () => {
    const { isBrowser } = DomData();
    const [scrollPosition, setScrollPosition] = useState(0);

    const updateScroll = useCallback(e => {
        setScrollPosition(window.scrollY);
    }, []);

    useEffect(() => {
        if (isBrowser) {
            document.addEventListener('scroll', updateScroll);
            return () => document.removeEventListener('scroll', updateScroll);
        }
    }, [isBrowser, updateScroll]);

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') {
                document.removeEventListener('scroll', updateScroll);
            }
        });
    }, [updateScroll]);

    return { scrollPosition };
};

export function NearViewport(id) {
    const elem = document.querySelector(`#${id}`);
    if (elem) {
        const bounds = elem.getBoundingClientRect();
        return bounds.top <= window.innerHeight || bounds.bottom <= 0;
    }
    return false;
}
