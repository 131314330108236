import tw, { styled } from 'twin.macro';

export const H1 = tw.h1`font-sans font-bold text-40 sm:text-50 md:text-70 lg:text-80 leading-none`;
export const H2 = tw.h2`uppercase font-bold text-34 sm:text-44 md:text-50 lg:text-60 leading-none`;
export const H3 = tw.h3`font-bold text-30 sm:text-37 md:text-40 lg:text-50 leading-none`;
export const H4 = tw.h4`font-sans font-bold text-21 sm:text-23 md:text-26 lg:text-30 leading-none`;
export const H5 = tw.h5`font-sans font-bold text-16 sm:text-18 md:text-20 lg:text-21`;
export const Small = tw.p`text-14 sm:text-15 lg:text-16 inline pb-16`;
export const Large = tw.p`text-16 sm:text-17 md:text-19 lg:text-21 pb-em leading-1.4`;
export const P = tw.p`text-15 sm:text-16 md:text-17 lg:text-18`;
export const LinkButton = styled.a`
    ${tw`text-14 leading-1.1 font-bold uppercase py-17 px-18 bg-white flex items-center justify-between`}
    transition: filter 0.5s ease, background 2s ease-in-out, opacity 0.3s ease;
    &:hover {
        @media (min-width: 768px) {
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        }
    }
    svg {
        ${tw`h-14 w-9 block`}
    }
    ${({ coloured }) => coloured && tw`bg-theme-color`}
`;
export const LinkButtonLabel = styled.span`
    ${tw`block`}
    ${({ showIcon }) => showIcon && tw`pr-16`}
    ${({ wide }) => wide && tw`lg:pr-150`}
    ${({ iconLeft }) => iconLeft && tw`pl-16`}
`;

export const RTE = styled.div`
    ${tw`block text-black`}
    h1, h2, h3, h4, h5, h6 {
        ${tw`py-em font-sans font-bold leading-none`}
    }
    p,
    ul,
    ol {
        ${tw`pb-em`}
    }
    h1 {
        ${tw`text-40 sm:text-50 md:text-70 lg:text-80`}
    }
    h2 {
        ${tw`uppercase text-34 sm:text-44 md:text-50 lg:text-60`}
    }
    h3 {
        ${tw`text-30 md:text-40 lg:text-50`}
    }
    h4 {
        ${tw` text-21 md:text-24 lg:text-26 xl:text-30`}
    }
    h5 {
        ${tw`text-16 sm:text-18 md:text-20 lg:text-21`}
    }
    h6 {
        ${tw`text-15 sm:text-16 md:text-17 lg:text-18`}
    }
    p {
        ${tw`leading-1.4 text-15 sm:text-16 md:text-17 lg:text-18 font-sans`}
    }
    p:last-child {
        ${tw`pb-0`}
    }
    hr {
        ${tw`h-2 w-60 bg-black mt-16 md:mt-18 mb-32 md:mb-36 border-0`}
    }
    hr:last-child {
        ${tw`mb-0 md:mb-0`}
    }
    ul {
        ${tw`list-disc pl-em`}
    }
    ol {
        ${tw`list-decimal pl-em`}
    }
    li {
        ${tw`pb-0.5em text-15 sm:text-16 md:text-17 lg:text-18 leading-1.4 font-sans`}
    }
    a {
        ${tw`inline-block text-black transition-opacity duration-300 underline opacity-70 hover:opacity-100`}
    }
`;
