import React from 'react';
import PropTypes from 'prop-types';
import {
    MenuWrapper,
    MenuItem,
    MenuLink,
    BackLink,
    LinkTransition,
} from './headerStyles';
import { DynamicLink } from 'utils/links';
import { matchCurrentPath } from 'utils/routes';

const HeaderMenuDesktop = ({
    menu,
    showWorkBackLink,
    showAboutBackLink,
    aboutBackLinkValue,
    path,
    isBrowser,
    domLoaded,
}) => (
    <MenuWrapper>
        {menu.map((item, i) => {
            const caseStudyParent =
                showWorkBackLink && item?.name?.toLowerCase()?.includes('work');
            const blogParent =
                showAboutBackLink &&
                item?.name?.toLowerCase()?.includes('about');
            const active = matchCurrentPath(path, item?.link?.url);
            return (
                <MenuItem key={i} active={active}>
                    <LinkTransition
                        ready={isBrowser && domLoaded}
                        animationDelay={50 + i * 100}
                    >
                        <DynamicLink
                            link={item?.link}
                            component={MenuLink}
                            title={item?.name}
                        >
                            {item?.name}
                        </DynamicLink>
                    </LinkTransition>
                    {caseStudyParent && (
                        <DynamicLink
                            link={item?.link}
                            component={BackLink}
                            title={`Back to all work`}
                        >
                            Back to all work
                        </DynamicLink>
                    )}
                    {blogParent && (
                        <DynamicLink
                            link={{ url: aboutBackLinkValue }}
                            component={BackLink}
                            title={`Back to all articles`}
                        >
                            Back to all articles
                        </DynamicLink>
                    )}
                </MenuItem>
            );
        })}
    </MenuWrapper>
);

HeaderMenuDesktop.propTypes = {
    menu: PropTypes.array,
    showBackLink: PropTypes.bool,
    path: PropTypes.string,
};

HeaderMenuDesktop.defaultProps = {
    menu: [],
    showBackLink: false,
};

export default HeaderMenuDesktop;
