import React from 'react';
import { Link } from 'gatsby';
import { styled } from 'twin.macro';

export const FormatLink = link => {
    return link
        ? link
              .replace('/case-studies', '/our-work')
              .replace('//home', '')
              .replace('/home', '')
              .replace('/menu-config', '')
        : '/';
};

export const DynamicLink = ({
    link,
    component,
    title,
    children,
    target,
    props,
}) => {
    const url = FormatLink(link?.route?.path || link?.url);
    const targetContent = target ? target : link?.target ? '_blank' : null;
    const label = title || link?.title;
    if (url) {
        if (
            url.includes('http') ||
            url.includes('tel:') ||
            url.includes('mailto:')
        ) {
            const Elem = styled.a`
                ${component?.__emotion_styles}
            `;
            return (
                <Elem
                    href={url}
                    target={targetContent}
                    title={label}
                    {...props}
                >
                    {children}
                </Elem>
            );
        } else {
            const Elem = styled(Link)`
                ${component?.__emotion_styles}
            `;
            return (
                <Elem to={url} target={targetContent} title={label} {...props}>
                    {children}
                </Elem>
            );
        }
    }
    return <></>;
};
