import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner, HeaderContainer } from './headerStyles';
import { HeaderLogo } from './headerLogo';
import { HeaderCall } from './headerCall';
import { DomData } from 'utils/dom';
import HeaderMenuDesktop from './headerMenuDesktop';
import HeaderMenuMobile from './headerMenuMobile';

const Header = ({
    siteTitle,
    menu,
    showWorkBackLink,
    showAboutBackLink,
    aboutBackLinkValue,
    path,
    phoneNumber,
    modalOpen,
}) => {
    const { isBrowser, screenWidth, domLoaded } = DomData();
    const HeaderMenuPeek = useMemo(
        () =>
            isBrowser && domLoaded && screenWidth !== 'small'
                ? React.lazy(() => import('./headerMenuPeek'))
                : null,
        [isBrowser, screenWidth, domLoaded]
    );

    return useMemo(
        () => (
            <Outer>
                <HeaderContainer as={`section`} width={`contained`}>
                    <Inner>
                        <HeaderLogo siteTitle={siteTitle} />
                        <HeaderMenuDesktop
                            menu={menu}
                            showWorkBackLink={showWorkBackLink}
                            showAboutBackLink={showAboutBackLink}
                            aboutBackLinkValue={aboutBackLinkValue}
                            path={path}
                            isBrowser={isBrowser}
                            domLoaded={domLoaded}
                        />
                        <HeaderCall
                            phone={phoneNumber}
                            isBrowser={isBrowser}
                            domLoaded={domLoaded}
                        />
                    </Inner>
                </HeaderContainer>
                {!modalOpen ? (
                    <HeaderMenuMobile menu={menu} path={path} />
                ) : null}
                {!modalOpen && HeaderMenuPeek ? (
                    <React.Suspense fallback={<div></div>}>
                        <HeaderMenuPeek
                            menu={menu}
                            title={siteTitle}
                            path={path}
                        />
                    </React.Suspense>
                ) : null}
            </Outer>
        ),
        [
            siteTitle,
            HeaderMenuPeek,
            menu,
            showWorkBackLink,
            showAboutBackLink,
            aboutBackLinkValue,
            path,
            phoneNumber,
            isBrowser,
            domLoaded,
            modalOpen,
        ]
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
    menu: PropTypes.array,
};

Header.defaultProps = {
    siteTitle: ``,
    menu: [],
};

export default Header;
