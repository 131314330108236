import React, { useMemo } from 'react';
import {
    CallWrapper,
    CallCopy,
    CallIcon,
    CallFadeWrapper,
} from './headerStyles';
import { Icon } from '../icon';

export const HeaderCall = ({ phone, isBrowser, domLoaded }) => {
    return useMemo(
        () =>
            phone ? (
                <CallFadeWrapper
                    ready={isBrowser && domLoaded}
                    animationDelay={500}
                >
                    <CallWrapper
                        href={`tel:${phone.replace(/ /g, '')}`}
                        id={`header-call`}
                    >
                        <CallCopy>Call</CallCopy>
                        <CallIcon>
                            <Icon name={`phone`} />
                        </CallIcon>
                    </CallWrapper>
                </CallFadeWrapper>
            ) : null,
        [phone, isBrowser, domLoaded]
    );
};

export default HeaderCall;
