import tw, { styled } from 'twin.macro';

export const Main = styled.main`
    ${tw`w-full block w-full z-1 relative`}
    ${({ minHeight }) => minHeight && tw`min-h-screen`}
`;
export const BgImage = styled.div`
    ${tw`fixed min-h-screen pointer-events-none top-0 right-0 w-screen z-0 flex justify-end`}
    svg {
        ${tw`max-w-1/3 w-364 h-auto`}
    }
`;
