require('./static/css/style.css');
const React = require('react');
const Layout = require('./src/layout/layout').default;

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log('new pathname', location)
    // console.log('old pathname', prevLocation ? prevLocation : null)
};
