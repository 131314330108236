import React from 'react';
import {
    Sub,
    Location,
    MapPin,
    Address,
    LinkGroup,
    BrandLink,
} from './footerStyles';
import { Icon } from '../icon';
import { DynamicLink } from 'utils/links';

export const FooterSubFooter = ({ addressLink }) => {
    return (
        <Sub>
            <DynamicLink
                link={addressLink}
                component={Location}
                props={{
                    rel: 'noopener',
                }}
            >
                <MapPin>
                    <Icon name={`map-pin`} />
                </MapPin>
                <Address>{addressLink?.title}</Address>
            </DynamicLink>
            <LinkGroup>
                {/* <DynamicLink
                    link={{url: `/`}}
                    component={SmallLink}
                    title={`Privacy`}
                    children={(`Privacy`)}
                />
                <DynamicLink
                    link={{url: `/`}}
                    component={SmallLink}
                    title={`Legal`}
                    children={(`Legal`)}
                /> */}
                <BrandLink
                    href={`https://umbraco.com/partners/partnerlist/calico/`}
                    title={`Umbraco Platinum Partner`}
                    target={`_blank`}
                    rel={`noopener`}
                >
                    <Icon name={`umbraco-partner`} />
                </BrandLink>
            </LinkGroup>
        </Sub>
    );
};

export default FooterSubFooter;
