import tw, { styled, css } from 'twin.macro';

export const Reveal = styled.div`
    ${tw`transition-all duration-500`}
    ${({ animationDelay }) => css`
        transition-delay: ${animationDelay}ms;
    `}
    ${css`
        opacity: 0;
        transform: translate3d(0, 2rem, 0);
    `}
    ${({ ready }) =>
        ready
            ? css`
                  opacity: 1;
                  transform: translate3d(0, 0, 0);
              `
            : css``}
`;
