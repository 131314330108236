import React from 'react';
import {
    TraditionContainer,
    TraditionWrapper,
    Flags,
    ImageWrapper,
    TraditionCopy,
    TraditionImage,
} from './footerStyles';
import { Container } from '../styles/containerStyles';

export const FooterTradition = () => {
    return (
        <TraditionContainer width={`full`}>
            <Container background={`transparent`} width={`contained`}>
                <TraditionWrapper>
                    <Flags>
                        <ImageWrapper>
                            <TraditionImage
                                src={'/images/ia-flag.png'}
                                width={104}
                                height={64}
                                alt={`Australian Aboriginal Flag`}
                            />
                        </ImageWrapper>
                        <ImageWrapper>
                            <TraditionImage
                                src={'/images/ts-flag.png'}
                                width={104}
                                height={68}
                                alt={`Torres Strait Islander Flag`}
                            />
                        </ImageWrapper>
                    </Flags>
                    <TraditionCopy>
                        Calico acknowledges the Traditional Owners of the land
                        where we work and live. We pay our respects to Elders
                        past and present.
                        <br />
                        We celebrate the stories, culture and traditions of
                        Aboriginal and Torres Strait Islander Elders, and of all
                        communities who also work and live on this land.
                    </TraditionCopy>
                </TraditionWrapper>
            </Container>
        </TraditionContainer>
    );
};

export default FooterTradition;
