import tw, { styled } from 'twin.macro';
import { Container } from '../styles/containerStyles';
import { Reveal } from 'utils/reveal';

export const HeaderContainer = tw(
    Container
)`bg-transparent pb-16 sm:pb-20 md:pb-40 h-136 sm:h-160 md:h-180 lg:h-208`;
export const Inner = tw.div`w-full flex items-center justify-between h-full`;
export const Outer = tw.header`w-full block bg-transparent relative top-0 left-0 min-h-136 md:min-h-180 lg:min-h-215`;
export const TitleLink = tw.a`block no-underline w-200 h-120 sm:w-240 sm:h-140 lg:w-292 lg:h-168 -ml-10 xl:-ml-40`;
export const LinkTransition = tw(Reveal)``;

export const MenuWrapper = styled.ul`
    ${tw`list-none w-full md:w-auto hidden md:flex`}
    ${({ reverse }) => (reverse ? tw`pt-0` : tw`md:pt-32`)}
`;
export const MenuItem = styled.li`
    ${tw`block pr-20 lg:pr-50 relative md:flex md:items-center`}
    &:last-of-type {
        ${tw`pr-0 lg:pr-0`}
    }
    div {
        will-change: transform;
    }
    a {
        ${({ active, reverse }) =>
            active && (reverse ? tw`border-white` : tw`border-black`)})}
    }
`;
export const MenuLink = styled.a`
    ${tw`block no-underline pt-16 pb-3 mb-8 md:border-b-2 md:text-17 lg:text-18
    transition-colors duration-300 text-right md:text-center font-bold`}
    &[aria-current="page"] {
        ${({ reverse }) => (reverse ? tw`border-white` : tw`border-black`)}
    }
    ${({ active }) => active && tw`border-black`}
    &:not([aria-current="page"]) {
        ${({ reverse }) =>
            reverse ? tw`hover:text-calico-link-white` : tw`hover:border-black`}
    }
    ${({ reverse, active }) =>
        reverse
            ? tw`text-white border-calico-mobile-nav`
            : active
            ? tw`text-black border-black`
            : tw`text-black border-transparent`}
`;

export const MobileMenuWrapper = tw.div`fixed bottom-0 left-0 md:hidden w-full z-50`;
export const MobileMenuList = tw.ul`w-full bg-calico-mobile-nav px-7per flex items-center justify-between`;
export const MobileMenuItem = tw.li`block`;
export const MobileMenuLink = styled.a`
    ${tw`block text-white font-bold hover:text-calico-link-white transition-colors duration-300 pt-22 pb-4 mb-16 border-b-2 text-14`}
    ${({ active }) => (active ? tw`border-white` : tw`border-transparent`)})}
`;

export const PeekMenu = styled.div`
    ${tw`hidden md:block w-full z-50 fixed top-0 left-0 bg-calico-mobile-nav transition-transform duration-300`}
    will-change: transform;
    ${({ visible }) => !visible && tw`translate-y-nfull`}
`;
export const PeekMenuWrapper = tw.div`flex items-center justify-between`;
export const PeekMenuContainer = tw(Container)`py-16`;
export const PeekMenuIcon = styled.a`
    ${tw`w-160 block -ml-8`}
    >svg {
        ${tw`h-full`}
    }
`;

export const BackLink = tw.a`hidden md:block absolute bottom-0 text-black opacity-70 font-bold hover:opacity-100 whitespace-nowrap mt-8 transition-opacity duration-500 translate-y-full`;

export const HiddenLabel = tw.span`hidden sm:inline-block`;
export const CallWrapper = tw.a`flex items-center justify-center pt-25 sm:pt-32 md:hidden`;
export const CallCopy = tw.span`inline-block font-bold text-13 text-black uppercase mr-8`;
export const CallIcon = tw.span`block w-13 max-w-13 h-22`;
export const CallFadeWrapper = tw(Reveal)`block md:hidden`;
