import React, { useMemo } from 'react';
import { TitleLink } from './headerStyles';
import { DynamicLink } from 'utils/links';
import { DomData } from 'utils/dom';

export const HeaderLogo = ({ siteTitle }) => {
    const { isBrowser, domLoaded } = DomData();
    const Logo = useMemo(
        () =>
            isBrowser && domLoaded
                ? React.lazy(() => import('static/icons/logo-ease.svg'))
                : null,
        [isBrowser, domLoaded]
    );
    return useMemo(
        () => (
            <DynamicLink
                link={{ url: `/` }}
                component={TitleLink}
                title={siteTitle}
            >
                {Logo && (
                    <React.Suspense fallback={<svg></svg>}>
                        <Logo />
                    </React.Suspense>
                )}
            </DynamicLink>
        ),
        [Logo, siteTitle]
    );
};

export default HeaderLogo;
