import React, { useEffect, useMemo, useState } from 'react';

import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { SkipLink } from '../components/skipLink/skipLink';
import { BgImage, Main } from './layoutStyles';
import { DomData } from 'utils/dom';
import { Icon } from '../components/icon';

const themeTransitionColours = [
    '#abf8dc',
    '#f8d0ab',
    '#adf8ab',
    '#b1abf8',
    '#f8abd9',
];

const Layout = ({ children, path, pageContext }) => {
    const { isBrowser } = DomData();
    const [transitionIndex, setTransitionIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const { globalData } = pageContext;

    const siteTitle = globalData?.metaTitle;

    const socialMenu = useMemo(() => {
        const list = [];
        if (globalData?.linkedInURL) {
            list.push({ label: 'linkedin', url: globalData?.linkedInURL });
        }
        if (globalData?.instagramURL) {
            list.push({ label: 'instagram', url: globalData?.instagramURL });
        }
        if (globalData?.twitterURL) {
            list.push({ label: 'twitter', url: globalData?.twitterURL });
        }
        if (globalData?.facebookURL) {
            list.push({ label: 'facebook', url: globalData?.facebookURL });
        }
        return list;
    }, [globalData]);

    pageContext.socialMenu = socialMenu;
    pageContext.setModalOpen = setModalOpen;

    useEffect(() => {
        if (themeTransitionColours[transitionIndex]) {
            document.documentElement.style.setProperty(
                '--themeColour',
                themeTransitionColours[transitionIndex]
            );
        }
        const transitionHandler = setTimeout(() => {
            setTransitionIndex(
                transitionIndex + 1 < themeTransitionColours.length
                    ? transitionIndex + 1
                    : 0
            );
        }, 15000);
        return () => {
            clearTimeout(transitionHandler);
        };
    }, [transitionIndex]);

    useEffect(() => {
        if (isBrowser) {
            document.body.className = `font-sans`;
            document.documentElement.setAttribute(`lang`, `en`);
        }
    }, [isBrowser]);

    // console.log(pageContext);

    return useMemo(
        () => (
            <>
                <SkipLink />
                <Header
                    siteTitle={siteTitle}
                    menu={globalData?.menu}
                    showWorkBackLink={
                        pageContext?.data?.contentType === 'caseStudyDetail'
                    }
                    showAboutBackLink={
                        pageContext?.data?.contentType === 'article'
                    }
                    aboutBackLinkValue={globalData?.blogRoot}
                    path={path}
                    phoneNumber={globalData?.phoneNumber}
                    modalOpen={modalOpen}
                />
                <Main
                    id={`main`}
                    minHeight={
                        children?.props?.pageContext?.data?.descendants?.items
                            ?.length > 1 ||
                        children?.props?.pageContext?.data?.grid?.length > 1
                    }
                >
                    {children}
                    <BgImage>
                        <Icon name={`background`} />
                    </BgImage>
                </Main>
                <Footer
                    socialMenu={socialMenu}
                    title={siteTitle}
                    contactGroupTitle={globalData?.contactGroupTitle}
                    contactLinkLabel={globalData?.contactLinkLabel}
                    phoneNumber={globalData?.phoneNumber}
                    emailAddress={globalData?.emailAddress}
                    addressLink={globalData?.addressData}
                />
            </>
        ),
        [
            pageContext,
            globalData,
            socialMenu,
            children,
            siteTitle,
            path,
            modalOpen,
        ]
    );
};

export default Layout;
