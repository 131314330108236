import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

const widthOptions = {
    default: tw`xl:max-w-container px-8per sm:px-7per md:px-6per lg:px-5per xl:px-0`,
    reduced: tw`lg:max-w-768 px-8per sm:px-7per md:px-6per lg:px-0`,
    contained: tw`xl:max-w-container px-8per sm:px-7per md:px-6per lg:px-5per xl:px-0`,
    xsfull: tw`xl:max-w-container px-0 sm:px-7per md:px-6per lg:px-5per xl:px-0`,
    smfull: tw`xl:max-w-container px-0 md:px-6per lg:px-5per xl:px-0`,
    mdreduced: tw`md:max-w-768 md:px-6per lg:px-0`,
    full: tw`w-full`,
};

const backgroundOptions = {
    black: tw`bg-black`,
    default: tw`bg-white`,
    transparent: tw`bg-transparent`,
    theme: tw`bg-theme-color`,
};

export const Container = styled.div`
    ${tw`w-full block mx-auto relative`}
    ${({ width }) => widthOptions[width] && widthOptions[width]}
${({ background }) =>
        backgroundOptions[background] && backgroundOptions[background]}
`;

Container.propTypes = {
    width: PropTypes.string,
    background: PropTypes.string,
};

Container.defaultProps = {
    width: `full`,
    background: `default`,
};
