import React, { useState, useEffect, useMemo } from 'react';
import {
    LogoRow,
    Logo,
    CtaRow,
    CtaTitle,
    CtaLinks,
    CtaLink,
    FollowRow,
    Icons,
    IconLink,
    FooterContainer,
    ContactButton,
    ButtonWrapper,
    FooterInner,
} from './footerStyles';
import { Container } from '../styles/containerStyles';
import { Icon } from '../icon';
import { FooterSubscribe } from './footerSubscribe';
import { FooterSubFooter } from './footerSubFooter';
import { NearViewport, ScrollStatus } from 'utils/dom';
import { DynamicLink } from 'utils/links';
import { FooterTradition } from './footerTradition';

const Footer = ({
    socialMenu,
    title,
    contactGroupTitle,
    contactLinkLabel,
    phoneNumber,
    emailAddress,
    addressLink,
}) => {
    const { scrollPosition } = ScrollStatus();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (!visible && NearViewport(`footer`)) {
            setVisible(true);
        }
    }, [visible, scrollPosition]);

    return useMemo(
        () => (
            <FooterContainer
                as={`footer`}
                width={`full`}
                background={`black`}
                id={`footer`}
            >
                <FooterInner background={`transparent`} width={`full`}>
                    <Container
                        as={`section`}
                        background={`transparent`}
                        width={`contained`}
                    >
                        <LogoRow>
                            <DynamicLink
                                link={{ url: `/` }}
                                component={Logo}
                                title={title}
                                children={<Icon name={`logo-reversed`} />}
                            />
                            <CtaRow>
                                <CtaTitle>{contactGroupTitle}</CtaTitle>
                                <CtaLinks>
                                    {contactLinkLabel && (
                                        <ButtonWrapper>
                                            <DynamicLink
                                                link={{ url: `/contact/` }}
                                                component={ContactButton}
                                                title={contactLinkLabel}
                                                children={contactLinkLabel}
                                                props={{
                                                    coloured: 'true',
                                                }}
                                            />
                                        </ButtonWrapper>
                                    )}
                                    {phoneNumber && (
                                        <DynamicLink
                                            link={{
                                                url: `tel:${phoneNumber.replace(
                                                    / /g,
                                                    ''
                                                )}`,
                                            }}
                                            component={CtaLink}
                                            title={phoneNumber}
                                            children={phoneNumber}
                                            props={{
                                                id: `footer-call`,
                                            }}
                                        />
                                    )}
                                    {emailAddress && (
                                        <DynamicLink
                                            link={{
                                                url: `mailto:${emailAddress}`,
                                            }}
                                            component={CtaLink}
                                            title={emailAddress}
                                            children={emailAddress}
                                        />
                                    )}
                                </CtaLinks>
                            </CtaRow>
                        </LogoRow>
                        <FollowRow>
                            <Icons>
                                {socialMenu.map((item, i) => (
                                    <DynamicLink
                                        key={i}
                                        link={item}
                                        component={IconLink}
                                        title={item.label}
                                        target={`_blank`}
                                        children={<Icon name={item.label} />}
                                    />
                                ))}
                            </Icons>
                            <FooterSubscribe />
                        </FollowRow>
                        <FooterSubFooter addressLink={addressLink} />
                    </Container>
                </FooterInner>
                <FooterTradition visible={visible} />
            </FooterContainer>
        ),
        [
            title,
            contactGroupTitle,
            contactLinkLabel,
            phoneNumber,
            emailAddress,
            socialMenu,
            visible,
            addressLink,
        ]
    );
};

export default Footer;
