import React from 'react';
import PropTypes from 'prop-types';
import {
    MobileMenuWrapper,
    MobileMenuList,
    MobileMenuItem,
    MobileMenuLink,
    HiddenLabel,
} from './headerStyles';
import { DynamicLink } from 'utils/links';
import { matchCurrentPath } from 'utils/routes';

const HeaderMenuMobile = ({ menu, path }) => {
    return (
        <MobileMenuWrapper>
            <MobileMenuList>
                {menu.map((item, i) => {
                    const active = matchCurrentPath(path, item?.link?.url);
                    return (
                        <MobileMenuItem key={i}>
                            {item.name === 'Our Work' ? (
                                <DynamicLink
                                    link={item?.link}
                                    component={MobileMenuLink}
                                    title={item?.name}
                                    props={
                                        active
                                            ? {
                                                  active: 'true',
                                              }
                                            : null
                                    }
                                >
                                    <HiddenLabel>Our</HiddenLabel> Work
                                </DynamicLink>
                            ) : (
                                <DynamicLink
                                    link={item?.link}
                                    component={MobileMenuLink}
                                    title={item?.name}
                                    children={item.name}
                                    props={
                                        active
                                            ? {
                                                  active: 'true',
                                              }
                                            : null
                                    }
                                />
                            )}
                        </MobileMenuItem>
                    );
                })}
            </MobileMenuList>
        </MobileMenuWrapper>
    );
};

HeaderMenuMobile.propTypes = {
    menu: PropTypes.array,
};

HeaderMenuMobile.defaultProps = {
    menu: [],
};

export default HeaderMenuMobile;
